<template>
  <div class="card" style="min-height: 200px">
    <div class="row mt-2">
      <div class="col-10"></div>
      <div class="col-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                 v-model="showHeader"
                 checked
          >
          <label class="form-check-label" for="flexSwitchCheckChecked">Include Header</label>
        </div>
      </div>
    </div>
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      :htmlToPdfOptions="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">

          <div class="p-1" v-if="showHeader">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img :src="company?.logo64" :alt="company?.name" class="img-fluid" style="max-height: 100px;"/>
              </div>
              <div class="col-12 col-lg-6"></div>
            </div>
          </div>

          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Fund Requisition
            </p>
            <hr>
          </div>

          <div class="row p-2">
            <div class="col-12 col-lg-6">
              <p><strong>Contact Details</strong></p>
              <p>
                <strong>Name: </strong> {{requisition?.contact_profile.full_name}}, <br>
                <strong>Phone: </strong> {{contactPhone}}, <br>
                <strong>E-mail: </strong> {{contactEmail}}
              </p>
            </div>
            <div class="col-12 col-lg-6 text-end">
              <p><strong>Fund Requisition No: </strong> {{requisition.fr_number}}</p>
              <p><strong>Fund Requisition Date: </strong> {{requisition.fr_date}}</p>
            </div>

            <div class="col-12 table-responsive py-2">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                <template v-if="requisitionMasters.length">
                  <template v-for="(requisitionMaster, index) in requisitionMasters">
                    <tr>
                      <td colspan="4" class="text-center">
                        <strong>
                          {{ requisitionMaster.requisition_number }} | {{ requisitionMaster.date }} | {{ requisitionMaster?.user?.name }} | {{ requisitionMaster?.project?.name }}
                        </strong>
                      </td>
                    </tr>

                    <tr v-for="(item, index) in requisitionMaster.general">
                      <td>{{item.product.name}}</td>
                      <td>{{item.quantity}} {{ item.product.description.unit.name }}</td>
                      <td>{{item.rate}}</td>
                      <td class="text-end">{{item.total_amount}}</td>
                    </tr>

                    <tr v-for="(item, index) in requisitionMaster.account">
                      <td>{{item.account_head.name}}</td>
                      <td>-</td>
                      <td>-</td>
                      <td class="text-end">{{item.total_amount}}</td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="3" class="text-end">
                      <strong>Total</strong>
                    </td>
                    <td class="text-end">{{ requisition.total_amount }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="4" class="text-center">No data</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>

            <div class="col-12 mt-2">
              {{requisition.description}}
            </div>
          </div>

          <div class="row p-2">
            <div class="row">
              <div class="col-12 col-lg-4 text-left mt-2">
                <p v-if="requisition.user">
                  {{requisition.user.name}} <br>
                </p>
                <hr>
                <p>
                  <strong>Prepared By </strong>
                </p>
              </div>
              <div class="col-12 col-lg-4 text-left mt-2">
                <p>
                  &nbsp; <br>
                </p>
                <hr>
                <p>
                  <strong>Recommended By </strong>
                </p>
              </div>
              <div class="col-12 col-lg-4 text-left mt-2">
                <p>
                  &nbsp; <br>
                </p>
                <hr>
                <p>
                  <strong>Checked By </strong>
                </p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-lg-4 text-left mt-2">
                <p>
                  &nbsp; <br>
                </p>
                <hr>
                <p>
                  <strong>Approved By </strong>
                </p>
              </div>
              <div class="col-12 col-lg-4 text-left mt-2">
                <p>
                  &nbsp; <br>
                </p>
                <hr>
                <p>
                  <strong>Reviewed By </strong>
                </p>
              </div>
              <div class="col-12 col-lg-4 text-left mt-2">
                <p>
                  &nbsp; <br>
                </p>
                <hr>
                <p>
                  <strong>Approved By </strong>
                </p>
              </div>
            </div>
          </div>

          <template v-if="showFooter">
            <hr>
            <div class="row">
              <div class="col-12 col-lg-12 mt-2 text-center" v-if="company.name">
                <p class="h4">{{ company.name }}</p>
                <p>
                  Address: {{company.address}}, <br>
                  Phone: {{company.phone}}, <br>
                  E-mail: {{company.email}}
                </p>
              </div>
            </div>
          </template>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="row mb-2">
      <div class="col-10"></div>
      <div class="col-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                 v-model="showFooter"
                 checked
          >
          <label class="form-check-label" for="flexSwitchCheckChecked">Include Footer</label>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>

<script setup>
import handleCompany from "@/services/modules/company";
import Loader from "@/components/atom/LoaderComponent";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import figureFormatter from '@/services/utils/figureFormatter'

const company = ref({});
const showHeader = ref(true);
const showFooter = ref(true);
const loader = ref(false);
const requisition = ref({
  contact_profile: {
    profile_items: []
  }
});
const html2Pdf = ref(null);
const {fetchCompanyInfo} = handleCompany();
const {fetchSingleFundRequisition} = handleRequisitions();
const {commaFormat, currencyToWord} = figureFormatter();
const route = useRoute();
const showError =  inject('showError');
const frNumber = computed(() => {
  return requisition.value.fr_number;
});
let contactPhone = ref('');
let contactEmail = ref('');
let requisitionMasters = ref([]);
const htmlToPdfOptions = ref({
  margin: 0.5,
  filename: frNumber,
  image: {
    type   : 'jpeg',
    quality: 0.98
  },
  enableLinks: false,
  html2canvas: {
    scale  : 1,
    useCORS: true
  },
  jsPDF: {
    unit       : 'in',
    format     : 'a4',
    orientation: 'portrait'
  }
});

const exportToPDF = () => {
  html2Pdf.value.generatePdf();
}

onMounted(() => {
  loader.value = true;
  const companyQuery = `?company_id=${route.params.companyId}`;
  const fetchCompany = fetchCompanyInfo(route.params.companyId);
  const fetchRequisition = fetchSingleFundRequisition(route.params.requisitionId, companyQuery);
  Promise.all([
    fetchCompany.then(res => {
      company.value = res.data;
    }),
    fetchRequisition.then(res => {
      requisition.value = res.data;

      if (requisition.value.contact_profile.profile_items.length){
        (requisition.value.contact_profile.profile_items).forEach((item) => {
          if (item.field == 'phone'){
            contactPhone.value = item.value;
          }
        });
      }

      if (requisition.value.contact_profile.profile_items.length){
        (requisition.value.contact_profile.profile_items).forEach((item) => {
          if (item.field == 'email'){
            contactEmail.value = item.value;
          }
        });
      }

      if (requisition.value.requisition_masters.length){
        requisitionMasters.value = requisition.value.requisition_masters;
      }
    })
  ])
    .then( () => loader.value = false )
    .catch( (err) => {
      showError("Something went wrong");
      loader.value = false;
    } )
});
</script>